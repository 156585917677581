import React, { useState } from "react";
import MyButton from "./ui/Button";
import MyModal from "./ui/MyModal";
import { useSendFeedbackMutation } from "../store/apis/pictoPartyApi";
import { toast } from "react-toastify";
import { getUserId, useAppSelector } from "../store/store";
import { Textarea, TextInput } from "flowbite-react";
import LoadingSpinner from "./loadingSpinner/LoadingSpinner";
import { EMAIL_REGEX, MAX_EMAIL_LENGTH, MAX_FEEDBACK_MESSAGE_LENGTH } from "../../../../common/Constants";

const FeedbackModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendFeedback, {isLoading:isSendingFeedback}] = useSendFeedbackMutation();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const userId= useAppSelector(getUserId);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function invalidInputs(){
    if(!userId){
      toast.error("UserID not found!");
      console.error("UserID not found!");
      return true;
    }

    if(!message){
      console.error("Empty feedback message!");
      toast.error("Empty feedback message!");
      return true;
    }
    if(message && message.trim().length>MAX_FEEDBACK_MESSAGE_LENGTH){
      console.error("Feedback message too long!");
      toast.error("Feedback message too long!");
      return true;
    }
    if(email && !EMAIL_REGEX.test(email)){
      console.error("Email input looks incorrect!");
      toast.error("Email input looks incorrect!");
      return true;
    }
    if(email && email.trim().length>MAX_EMAIL_LENGTH){
      console.error("Email too long!");
      toast.error("Email too long!");
      return true;
    }
    if(firstName && firstName.trim().length>MAX_EMAIL_LENGTH){
      console.error("First Name too long!");
      toast.error("First Name too long!");
      return true;
    }
    return false;
  }

  async function sendFeedbackHandler(){
    try {
      if(invalidInputs()){
        return;
      }

      const result = await sendFeedback({message, uuid: userId, email, firstName, submittedAt: Date.now()}).unwrap();
      console.log(result);
      if(result.error){
        toast.error(`Error in sending feedback! ${result.message} ${result.error}`);
        console.error(`Error in sending feedback! ${result.message} ${result.error}`);
        return;
      }
      toast.success(`Feedback sent! ${result.message}`);
      closeModal();
    } catch (e) {
      // Sentry.captureException(`Error while sending feedback: ${e}`);
      toast.error(`Error in sending feedback! ${e}`);
      console.error(`Error in sending feedback! ${e}`);
    }
  }

  const messageCharsLeft = MAX_FEEDBACK_MESSAGE_LENGTH - message.trim().length;

  return (
    <div className="fixed bottom-4 right-8 transform rotate-90 origin-bottom-right bg-slate-600 px-2 py-1">
      <button onClick={openModal}>Feedback 👍/👎</button>
      <MyModal
        isOpen={isModalOpen}
        setOpenModal={setIsModalOpen}
        headerContent={<p>Feedback / Comments</p>}
        bodyContent={
        <div>
          <p className="text-base text-slate-700">We'd love to hear from you!</p>
          <TextInput 
            placeholder="(Optional) First Name"
            value={firstName} 
            onChange={(e)=>setFirstName(e.target.value)} 
            className={`${isSendingFeedback?'text-blue-700':'text-gray-800'} flex-grow p-1 mr-1 focus:outline-none focus:border-blue-500`}
          />
          <TextInput 
            placeholder="(Optional) Email"
            value={email} 
            onChange={(e)=>setEmail(e.target.value)} 
            className={`${isSendingFeedback?'text-blue-700':'text-gray-800'} flex-grow p-1 mr-1 focus:outline-none focus:border-blue-500`}
          />
          <Textarea
            placeholder="Type your feedback/comment here"
            required
            rows={4}
            value={message}
            color={isSendingFeedback?"warning":"base"}
            className={`${isSendingFeedback?'text-blue-700':'text-gray-800'} flex-grow p-1 mr-1 focus:outline-none focus:border-blue-500`}
            onChange={(e)=>setMessage(e.target.value)}
          />
          <p className={`text-right text-base ${messageCharsLeft>=0?'text-green-800':'text-red-700'}`}>Characters left: {messageCharsLeft}</p>
        </div>}
        footerContent={
          <div className="flex justify-around w-full">
            <MyButton danger rounded onClick={closeModal}>Cancel</MyButton>
            <MyButton success rounded onClick={sendFeedbackHandler} disabled={isSendingFeedback}>
              Send Message {isSendingFeedback && <div><LoadingSpinner/></div>}
            </MyButton>
          </div>
        }
      />
    </div>
  );
};

export default FeedbackModal;